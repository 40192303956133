import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from '../images/forent-logo.png'
import "./index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className={'content'}>
      <img src={logo} alt=""/>
    <h3>Hier entsteht eine neue Website</h3>
    </div>
  </Layout>
)

export default IndexPage
